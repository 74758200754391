<template>
  <popup-dialog :title="title" @close="close" v-if="visible">
    <div class="html-desc" v-html="content"></div>
  </popup-dialog>
</template>

<script>
import PopupDialog from "@/components/popupDialog";
export default {
  name: "serviceAgreement",
  components: {
    PopupDialog
  },
  data() {
    return {
      title: "",
      content: "",
      visible: false,
    }
  },
  methods: {
    open(name, e) {
      this.title = name;
      this.content = e;
      this.visible = true;
    },
    close() {
      this.visible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .popup-wrap {
    position: relative;
    width: 800px !important;
    .html-desc {
      margin-top: 30px;
      height: 600px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: transparent; /* 滚动条轨道的背景色 */
      }
      &::-webkit-scrollbar-thumb {
        cursor: pointer;
        background: #999; /* 滚动条实际可拖动部分的颜色 */
        border-radius: 8px; /* 滚动条圆角 */
        position: absolute; /* 绝对定位 */
        right: 0; /* 滚动条浮动到最右侧 */
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  ::v-deep {
    .popup-wrap {
      width: 90% !important;
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  ::v-deep {
    .popup-wrap {
      width: 90% !important;
    }
  }
}
</style>