import axios from 'axios'
import { Message } from 'element-ui'

// 是否显示重新登录
export let isRelogin = { show: false };
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: "https://api.cf40.com/",
    // baseURL: "//40renapi.muyiapp.com/",
    // 超时
    timeout: 100000
});
// request拦截器
service.interceptors.request.use(config => {
    // 获取token
    const token = localStorage.getItem("cf40-token");
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    // console.log(isToken)
    // console.log(token)
    // 是否需要防止数据重复提交
    // const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
    if (token && !isToken) {
        config.headers['Authorization'] = 'Bearer ' + token // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
}, error => {
    console.log(error)
    Promise.reject(error)
});
// 响应拦截器
service.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        // 获取错误信息
        const msg = res.data.msg;
        // 二进制数据则直接返回
        if (res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer') {
            return res.data
        }
        if (code === 500) {
            Message({ message: msg, type: 'error' })
        } else if (code === 601) {
            Message({ message: msg, type: 'warning' })
        } else if (code !== 200) {
            // Notification.error({ title: msg })
            Message({ message: msg, type: 'error' })
        }
        return res.data
    },
    error => {
        let { message, response } = error;
        if (response.status === 401) {
            if (!isRelogin.show) {
                isRelogin.show = false;
                localStorage.clear();
                location.reload();
            }
        } else if (response.data.msg) {
            message = response.data.msg;
        } else if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        Message({ message: message, type: 'error', duration: 5 * 1000 })
        return response.data;
    });

export default service;
